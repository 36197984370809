<!--
 * @Author: your name
 * @Date: 2020-12-02 19:47:50
 * @LastEditTime: 2022-10-19 17:34:22
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BusinessPlatform\src\views\logisticsManagement\mapMonitoring\materialTransMap.vue
-->
<template>
    <div class="materialTransMap">
        <!-- 搜索区域 -->
        <div class="search-con">
            <div class="station-list el_left" v-if="stationList.length>1">
                <el-select
                    v-model="currentStationId"
                    placeholder="请选择"
                    @change="changeStation"
                >
                    <el-option
                        v-for="item in stationList"
                        :key="item.station_id"
                        :label="item.name"
                        :value="item.station_id"
                    >
                    </el-option>
                </el-select>
            </div>
            <el-input
                class="search-box el_left bm-search-box"
                v-model="keyword"
            ></el-input>
            <button class="search-btn">
                <span class="iconfont iconsousuo1"></span>
            </button>
        </div>
        <!-- 右侧面板 -->
        <div class="right-panel">
            <el-input
                class="search-input"
                v-model="searchVal"
                placeholder="请输入供应单号/车牌号"
                suffix-icon="el-icon-search"
                @change="onSearch"
            ></el-input>
            <ul class="list-con" v-if="list.length>0">
                <li v-for="item in list" :key="item.supply_no">
                    <div class="supply-no">
                        <span class="iconfont iconorder"></span>
                        <span>供应单号:{{ item.supply_no }}</span>
                    </div>
                    <div class="drive-info">
                        <span><i class="iconfont iconchepaihao1"></i>{{ item.plate_number }}</span>
                        <span><i class="iconfont icondriver"></i>{{ item.driver_name }}</span>
                        <span><i class="iconfont icondianhua1"></i>{{ item.driver_phone }}</span>
                    </div>
                    <div class="sort-num">
                        <div>
                            <span class="spec-code">
                                <span v-if="item.type_name">{{ item.type_name }}</span>
                                <span v-if="item.goods_name">-{{ item.goods_name }}</span>
                                <span v-if="item.spec_name">-{{ item.spec_name }}</span>
                            </span>
                        </div>
                        <div class="sort-con">
                            当前排在第<span class="num">{{ item.sort_num }}</span>位
                        </div>
                    </div>
                    <div class="time-con">
                        <div>
                            <div class="time">
                                {{ item.begin_time }}
                            </div>
                            <div class="lable">
                                出厂时间
                            </div>
                        </div>
                        <div class="arrow-con">
                            <div class="distanse">
                                {{ item.to_distance }}km
                            </div>
                            <div class="distanse">
                                <span class="tag">{{ item.plate_number }}</span>
                            </div>
                        </div>
                        <div>
                            <div class="time">
                                {{ item.estimated_time }}
                            </div>
                            <div class="lable">
                                预计到达时间
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            <div v-else class="no-data">
                暂无数据
            </div>
        </div>
        <!-- 地图组件 -->
        <baidu-map
            :center="mapCenter"
            :zoom="zoom"
            class="amap-demo"
            @ready="handleReady"
        >
            <bm-local-search
                :keyword="keyword"
                :location="searchOption"
                :searchcomplete="onSearchResult"
                :auto-viewport="true"
                class="local-search"
            ></bm-local-search>
            <!-- 车辆点标记 -->
            <bm-marker
                v-for="(marker, index) in markers"
                :key="index"
                :position="marker.position"
                :icon="{url: marker.icon, size: marker.size}"
                :rotation="marker.rotation"
                @click="switchWindow(index, marker, marker.position)"
            >
                <bm-label
                    :content="`${marker.plate_number}（${marker.sort_num}）`"
                    :offset="marker.offset"
                ></bm-label>
            </bm-marker>
            <!-- 场站点标记 -->
            <bm-marker
                :position="stationMarker.position"
                :icon="{url: stationMarker.icon, size: stationMarker.size}"
            >
            </bm-marker>
            <!-- 信息窗体 -->
            <bm-info-window
                :position="windows.position"
                :show="windows.visible"
                :offset="windows.offset"
                :close-on-click="true"
                class="car-info-window"
            >
                <div class="container">
                    <div class="top">
                        <span class="iconfont iconorder"></span>
                        <span>供应单号:{{ switchData.supply_no }}</span>
                    </div>
                    <div class="drive-info">
                        <span><i class="iconfont iconchepaihao1"></i>{{ switchData.plate_number }}</span>
                        <span><i class="iconfont icondriver"></i>{{ switchData.driver_name }}</span>
                        <span><i class="iconfont icondianhua1"></i>{{ switchData.driver_phone }}</span>
                    </div>
                    <div class="middle">
                        <div>
                            <span v-if="switchData.type_name">{{ switchData.type_name }}</span>
                            <span v-if="switchData.goods_name">-{{ switchData.goods_name }}</span>
                            <span v-if="switchData.spec_name">-{{ switchData.spec_name }}</span>
                        </div>
                        <div>当前排在第<span class="num">{{ switchData.sort_num }}</span>位</div>
                    </div>
                    <div class="time-con">
                        <div>
                            <div class="time">
                                {{ switchData.begin_time }}
                            </div>
                            <div class="lable">
                                出厂时间
                            </div>
                        </div>
                        <div class="arrow-con">
                            <div class="distanse">
                                {{ switchData.to_distance }}km
                            </div>
                            <div class="distanse">
                                <span class="tag">{{ switchData.plate_number }}</span>
                            </div>
                        </div>
                        <div>
                            <div class="time">
                                {{ switchData.estimated_time }}
                            </div>
                            <div class="lable">
                                预计到达时间
                            </div>
                        </div>
                    </div>
                </div>
            </bm-info-window>
        </baidu-map>
    </div>
</template>

<script>
import Vue from 'vue';
import BaiduMap from 'vue-baidu-map';
// import GeoUtils from '@/plugins/BMapLibGeoUtils/index';
Vue.use(BaiduMap, {
    // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
    ak: '7FR14186sYk3MqjP9ggWxuHx1AQkRzZX',
});
export default {
    components: {},
    props: {},
    data() {
        return {
            // 缩放比例
            zoom: 15,
            // 地图中心点
            mapCenter: { lng: 0, lat: 0 },
            // 模糊搜索
            keyword: '',
            // 搜索范围
            searchOption: '全国',
            // map对象
            BMap: {},
            // map实例对象
            mapObj: {},
            // 列表搜索条件
            searchVal: '',
            // 场站
            stationList: [],
            // 当前场站id
            currentStationId: '',
            // 运输单列表
            list: [],
            // 点标记
            markers: [],
            // 点信息窗体
            windows: {
                position: { lng: 0, lat: 0 },
                visible: false,
                offset: { width: -10, height: -20 },
            },
            switchData: {},
            // 场站点标记
            stationMarker: {
                position: { lng: 0, lat: 0 },
                icon: require('../../../assets/images/productionManagement/flag_green.png'),
                size: { width: 26, height: 24 },
            },
        };
    },
    watch: {},
    computed: {},
    created() {},
    mounted() {
        this.getStationUser();
        setInterval(() => {
            this.getCarsLocation();
        }, 60000);
    },
    methods: {
        // 地图加载完毕
        handleReady({ BMap, map }) {
            this.BMap = BMap;
            this.mapObj = map;
            // 开启鼠标滚动缩放地图
            map.enableScrollWheelZoom(true);
            // 设置中心点、缩放级别
            this.mapCenter.lng = 116.404;
            this.mapCenter.lat = 39.915;
            this.zoom = 15;
        },
        // 搜索
        onSearchResult(pois) {
            if (pois.length > 0) {
                this.mapCenter = [pois[0].lng, pois[0].lat];
                this.zoom = 15;
            }
        },
        // 切换场站
        changeStation() {
            const res = this.stationList.filter(item => item.station_id === this.currentStationId);
            const markObj = {
                position: { lng: res[0].longitude, lat: res[0].latitude },
                icon: require('../../../assets/images/productionManagement/flag_green.png'),
                size: { width: 26, height: 24 },
            };
            this.stationMarker = markObj;
            this.getCarsLocation();
        },
        // 拉取用户关联场站
        getStationUser() {
            const Uid = this.$takeTokenParameters('Uid');
            this.$axios
                .get('/interfaceApi/baseinfo/stationmanger/get_station_user?userId=' + Uid)
                .then(res => {
                    if (res.length > 0) {
                        this.stationList = res;
                        this.currentStationId = res[0].station_id;
                        const markObj = {
                            position: { lng: res[0].longitude, lat: res[0].latitude },
                            icon: require('../../../assets/images/productionManagement/flag_green.png'),
                            size: { width: 26, height: 24 },
                        };
                        this.stationMarker = markObj;
                        this.getCarsLocation();
                    }
                })
                .catch(error => {
                    this.$message.error(error.TypeError);
                });
        },
        // 点击点标记弹出信息窗体
        switchWindow(tab, item, position) {
            this.switchData = item;
            this.windows.visible = false;
            this.windows.position = position;
            this.$nextTick(() => {
                this.windows.visible = true;
            });
            // const plate_number = item.plate_number;
            // this.getVehicleNo(plate_number, tab);
        },
        // 右侧列表搜索
        onSearch() {
            this.getCarsLocation();
        },
        // 拉取场站下所有车辆位置
        async getCarsLocation() {
            const res = await this.$axios.$get('/interfaceApi/wechat/carlocation/get_cars_location?station_id='
            + this.currentStationId + '&keywords=' + this.searchVal);
            if (res) {
                if (res.length > 0) {
                    this.mapCenter = { lng: res[0].longitude, lat: res[0].latitude };
                    let arr = [];
                    const makers = [];
                    arr = res.map(item => {
                        if (item.to_distance) {
                            item.to_distance = (item.to_distance / 1000).toFixed(2);
                        }
                        if (item.begin_time) {
                            item.begin_time = item.begin_time.substr(11, item.begin_time.length);
                        }
                        if (item.estimated_time) {
                            item.estimated_time = item.estimated_time.substr(11, item.estimated_time.length);
                        }
                        const markObj = {
                            position: { lng: item.longitude, lat: item.latitude },
                            icon: require('../../../assets/images/productionManagement/blue.png'),
                            size: { width: 28, height: 45 },
                            offset: { width: -20, height: 40 },
                            rotation: item.direction,
                            plate_number: item.plate_number,
                            sort_num: item.sort_num,
                        };
                        const newObj = { ...markObj, ...item };
                        makers.push(newObj);
                        return item;
                    });
                    this.markers = makers;
                    this.list = arr;
                } else {
                    this.list = [];
                    this.markers = [];
                }
            }
        },

    },
};
</script>
<style lang="stylus">
.materialTransMap
    height 100%
    position: relative;
    .amap-demo
        width 100%
        height 100%
        position: relative;
        .local-search
            position absolute
            top 0.6rem
            left 0.2rem
        .car-info-window
            .container
                width 3.2rem
                height 1.4rem
                .top
                    color #0189FC
                    line-height .32rem
                    .iconfont
                        margin-right .05rem
                .drive-info
                    width 100%;
                    height .36rem
                    line-height .36rem
                    display flex
                    align-items center
                    span
                        display flex
                        align-items center
                        width 31%;
                        font-size 0.14rem;
                        color: #333;
                        .iconfont
                            margin-right .05rem
                            font-size 0.16rem;
                .middle
                    display flex
                    justify-content space-between
                    align-items center
                    height .35rem
                    background-color #F0F0F0
                    padding 0 .1rem
                    >div
                        &:nth-child(1)
                            font-size .14rem
                        &:nth-child(2)
                            font-size .12rem
                            .num
                                font-size .16rem
                                color #FF6600
                .time-con
                    display flex
                    // align-items center
                    height .59rem
                    padding .15rem
                    .arrow-con
                        background url(./../../../assets/images/logisticsManagement/arrow_blue.png) no-repeat bottom center
                        width 148px
                        height 0.22rem
                        margin 0 0.1rem
                        .distanse
                            text-align center
                            color #6DD400
                    .time
                        font-size .14rem
                    .lable
                        white-space nowrap
                        font-size .12rem
                        color #979EA7
                    .tag
                        padding .02rem .05rem
                        font-size .12rem
                        color #FF8534
                        margin-right .1rem
                        margin-top .1rem
    .search-con
        position absolute
        top .4rem
        left .4rem
        z-index 10
        display flex
        .search-box
            width 5rem
            height 0.4rem
            background rgba(0, 0, 0, 0)
            input
                font-size 0.16rem
                height 0.4rem
                line-height 0.4rem
                color #333
                border-radius 2px 0 0 4px
                background #fff
            .search-tips
                width 100%
        .search-btn
            width .60rem
            height .40rem
            background: #1577D2;
            border-radius: 0px 4px 4px 0px;
            color #fff
            border none
            cursor pointer
        .station-list
            margin-bottom 0.1rem
            margin-right 0.2rem
            .el-select
                width 1rem
                height 0.4rem
                .el-input__inner
                    height 0.4rem
                    line-height 0.4rem
                    color #333
    .right-panel
        position absolute
        right .4rem
        top .4rem
        width 3.86rem
        background #3A5173
        box-shadow 2px 2px 6px 0px rgba(0, 0, 0, 0.3)
        border-radius 2px
        height calc(100% - .8rem)
        z-index 10
        padding .2rem
        box-sizing border-box
        .search-input
            margin-bottom .2rem
            .el-input__inner
                height .32rem
                background-color #314663
                border 1px solid #314663
                color #fff
                &::-webkit-input-placeholder
                    font-size .14rem
                    color #82A7DF
        .no-data
            text-align: center;
            margin-top: .2rem;
            color: #ddd;
        .list-con
            height calc(100% - .52rem)
            overflow-y auto
            overflow-x hidden
            li
                background #49648B
                box-shadow 0px 0px 4px 0px rgba(0, 0, 0, 0.2)
                border-radius: 2px
                margin-bottom .1rem
                .supply-no
                    height .36rem
                    line-height .36rem
                    padding-left .15rem
                    color #32C5FF
                    font-size 0.16rem;
                    border-bottom 1px solid #3a5173;
                    .iconfont
                        margin-right .05rem
                        font-size 0.16rem;
                .drive-info
                    width 100%;
                    height .36rem
                    line-height .36rem
                    padding-left .15rem
                    display flex
                    span
                        display flex
                        align-items center
                        width 31%;
                        font-size 0.14rem;
                        color #fff
                        .iconfont
                            margin-right .05rem
                            font-size 0.16rem;
                .sort-num
                    height .35rem
                    background-color #405A7F
                    padding 0 .15rem
                    display flex
                    justify-content space-between
                    font-size .14rem
                    align-items center
                    .spec-code
                        color #fff
                    .sort-con
                        font-size .12rem
                        color #82A7DF
                        .num
                            font-size .16rem
                            color #FF6600
                .time-con
                    display flex
                    // align-items center
                    height .69rem
                    padding .15rem
                    .arrow-con
                        background url(./../../../assets/images/logisticsManagement/arrow_blue.png) no-repeat bottom center
                        width 1.48rem
                        height .22rem
                        margin 0 .2rem
                        .distanse
                            font-size 0.16rem;
                            text-align center
                            color #6DD400
                    .time
                        font-size .14rem
                        color #fff
                    .lable
                        white-space nowrap
                        font-size .12rem
                        color #82A7DF
                    .tag
                        padding .02rem .05rem
                        font-size .12rem
                        color #FF8534
                        margin-right .1rem
                        margin-top .1rem

</style>
